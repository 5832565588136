<template>
  <div
    v-if="statusLabel"
    :class="{
      'border-eggshell before:bg-eggshell': status === 'ready-build',
      'border-green before:bg-green':
        status === 'ready' || status === 'ready-move-in',
      'border-orange before:bg-orange': status === 'under-construction',
      'bg-red border-red text-white before:bg-white': status === 'sold',
      'border-yellow before:bg-yellow': status === 'under-offer',
      'border-coral-reef before:bg-coral-reef': status === 'off-plan',
    }"
    class="text-xxs flex items-center gap-1 rounded-3xl border-2 px-1.5 py-0.5 font-medium text-gray-900 before:block before:size-2 before:rounded-full before:content-['']"
  >
    {{ statusLabel }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  status?: string
}>()

const { fetchStatusEntries } = usePropertyStatus()
const { findPropertyDevStatusEntryByStatus } = usePropertyStatusEntriesStore()

await fetchStatusEntries()

const statusLabel = computed(
  // TODO: CHECK - this only shows values from the dev status entries, should it also search the job status entries for a match?
  () => findPropertyDevStatusEntryByStatus(props.status)?.name || '',
)
</script>
